export default class Rate {
    constructor(data, systemFrom, systemTo) {
        this.id = data.id;
        //this.price = data.price;
        this.slug = data.slug;
        this.type = data.type.value;
        this.systemFrom = systemFrom;
        this.systemTo = systemTo;
        this.ranges = data.ranges.sort((a, b) => b.min - a.min);
        this.order = data.order;
    }

    static getAllUniqueRates(ratesData, systemList) {
        const rateList = [];
        const uniqueRateIds = new Set();

        ratesData.forEach(rateData => {
            const systemFromId = rateData.from.id;
            const systemToId = rateData.to.id;

            const systemFrom = systemList.find(s => s.id === systemFromId);
            const systemTo = systemList.find(s => s.id === systemToId);

            if (systemFrom && systemTo) {
                const rate = new Rate(rateData, systemFrom, systemTo);

                if (!uniqueRateIds.has(rate.id)) {
                    uniqueRateIds.add(rate.id);
                    rateList.push(rate);
                }
            }
        });

        return rateList;
    }

    searchPriceByAmount(amount) {
        return this.ranges.find(r => amount >= r.min)
            ? this.ranges.find(r => amount >= r.min).price
            : this.ranges[this.ranges.length - 1].price
    }

    searchPriceByResult(result) {
        return this.ranges.find(r => result >= this.convertPrice(r.min))
            ? this.ranges.find(r => result >= this.convertPrice(r.min)).price
            : this.ranges[this.ranges.length - 1].price
    }

    isBetterRange(amount) {
        const index = this.ranges.findIndex(r => amount >= r.min);
        return index !== 0
    }

    getSuggestionText(amount) {
        const index = this.ranges.findIndex(r => amount >= r.min);
        return 'Tenemos un mejor precio a partir de ' + this.ranges[index -1 ].min + ' ' + this.systemFrom.currency.abbreviation;
    }

    convertPrice(amount) {
        let amountAfterTaxes = amount - this.systemFrom.tax;
        let option = this.type;
        let convertedAmount;
        if (Number(option) === 1) {
            convertedAmount = amountAfterTaxes * this.searchPriceByAmount(amount);
        } else {
            convertedAmount = amountAfterTaxes / this.searchPriceByAmount(amount);
        }
        return convertedAmount > 0 ? convertedAmount : 0
    }

    unConvertPrice(amount) {
        //let amountAfterTaxes = amount + this.systemFrom.tax;
        let option = this.type;
        if (Number(option) === 1) {
            return amount / this.searchPriceByResult(amount) + this.systemFrom.tax;
        } else {
            return amount * this.searchPriceByResult(amount) + this.systemFrom.tax;
        }
    }

    buildExampleText() {
        //TODO: ajustar esto
        const amount = this.ranges[this.ranges.length - 1].min
        const transformed = this.convertPrice(amount).toFixed(2)
        return 'Vos enviás ' + amount + ' ' + this.systemFrom.currency.abbreviation + ' y recibís ' + transformed + ' ' + this.systemTo.currency.abbreviation;
    }

    buildExampleEquation() {
        //TODO: ajustar esto
        const amount = this.ranges[this.ranges.length - 1].min
        const transformed = this.convertPrice(amount).toFixed(2)
        return '(' + this.minAmountToReceive() + ' * ' + this.searchPriceByAmount(0) + ') - ' + this.systemFrom.tax + ' = ' + transformed;
    }

    minAmountToReceive() {
        return this.ranges[this.ranges.length - 1].min
    }

    maxAmountToReceive() {
        return this.ranges[0].max
    }

    minAmountToSend() {
        return this.convertPrice(this.minAmountToReceive())
    }

    maxAmountToSend() {
        return this.convertPrice(this.maxAmountToReceive())
    }

    initialAmount() {
        return this.minAmountToReceive()
    }

    validateMinAndMax(amount) {
        return amount >= this.minAmountToReceive() &&
            amount <= this.maxAmountToReceive()
    }

    validatePrecision(amount) {
        return (this.convertPrice(amount) % this.systemTo.roundPrecision) < 1e-2
    }

    validateInput(amount) {
        return this.validateMinAndMax(amount) && this.validatePrecision(amount)
    }

    validateResult(result) {
        return result >= this.minAmountToSend() &&
            result <= this.maxAmountToSend() &&
            (result % this.systemTo.roundPrecision) < 1e-2
    }

    getRoundedUpResult(result) {
        return Math.ceil(result / this.systemTo.roundPrecision) * this.systemTo.roundPrecision
    }

    getRoundedDownResult(result) {
        return Math.floor(result / this.systemTo.roundPrecision) * this.systemTo.roundPrecision
    }

    getRoundedUpOption(result) {
        return this.unConvertPrice(this.getRoundedUpResult(result))
    }

    getRoundedDownOption(result) {
        return this.unConvertPrice(this.getRoundedDownResult(result))
    }

    getAmountSuggestions(amount) {
        let result = this.convertPrice(amount)
        let rounded_down_option = this.getRoundedDownOption(result);
        let rounded_up_option = this.getRoundedUpOption(result);

        let suggestions = []

        if(this.validateInput(rounded_down_option)){
            suggestions.push(rounded_down_option)
        }

        if(this.validateInput(rounded_up_option)){
            suggestions.push(rounded_up_option)
        }

        return suggestions
    }

    getMinAmount() {
        if(this.systemTo.roundPrecision > 0 && this.convertPrice(this.initialAmount()) % this.systemTo.roundPrecision === 0 ) {
            return this.getRoundedUpOption(this.convertPrice(this.initialAmount()))
        }
        return this.initialAmount()
    }
}